'use client';

import './QuestCard.css';

import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { Avatar } from '@zealy/design-system';

import { TaskBadges } from '#components/TaskBadge/TaskBadges';
import { cn } from '#utils/utils';

import type { QuestCardProps } from './QuestCard.types';
import { QuestStatus } from './components/QuestStatus';
import { getQuestStatus, QuestStatusReason } from './components/QuestStatus/QuestStatus.utils';
import { QuestUnlockInfo } from './components/QuestUnlockInfo';
import { Recurrence } from './components/RecurrenceBadge';
import { RewardStack } from './components/RewardStack/RewardStack';
import { VoteBadge } from './components/VoteBadge';
import { QuestCardSkeleton } from './QuestCard.skeleton';
import { container, info, title } from './QuestCard.styles';

export const QuestCard = ({
  as: Component = Link,
  quest,
  className,
  isSubscriptionLimitReached,
  href,
  displayCommunityData,
  ...rest
}: QuestCardProps) => {
  const router = useRouter();
  const [shouldShowHowToUnlock, setShouldShowHowToUnlock] = useState(false);
  const t = useTranslations('quest');

  const toggleHowToUnlock = () => setShouldShowHowToUnlock(prev => !prev);
  const hideHowToUnlock = () => setShouldShowHowToUnlock(false);

  const questStatus = getQuestStatus(quest, !!isSubscriptionLimitReached);

  const isDisabled =
    !questStatus.canClaim && questStatus.reason !== QuestStatusReason.CLAIM_REWARDS;

  const onClickWithoutPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    if (quest.locked && quest.conditions.length > 0) {
      toggleHowToUnlock();
    }

    if (isDisabled) {
      return e.preventDefault();
    }

    router.push(href);
  };

  const Root = isDisabled ? 'div' : Component;

  return (
    <QuestUnlockInfo
      onHide={hideHowToUnlock}
      matchers={quest.conditions}
      open={shouldShowHowToUnlock}
      operator={quest.conditionOperator}
    >
      <div
        className={cn(container, 'relative', isDisabled ? '' : 'quest-card-gradient', className)}
        onClick={onClickWithoutPropagation}
      >
        <Root
          href={isDisabled ? undefined : href}
          aria-disabled={isDisabled}
          data-disabled={isDisabled}
          {...rest}
          className="w-full z-10 relative"
          id={quest.id}
        >
          <div className={info}>
            {
              <span
                className={cn(title, {
                  'text-tertiary': isDisabled || !quest.name,
                })}
              >
                {quest.name || t('placeholder')}
              </span>
            }
            {displayCommunityData && (
              <div className="flex gap-100 items-center mb-100">
                <Avatar
                  className="flex-shrink-0 pointer-events-none"
                  tabIndex={-1}
                  size="xxs"
                  name={displayCommunityData.name}
                  src={displayCommunityData.imageUrl}
                />
                <span className="text-secondary body-md max-w-[200px] truncate">
                  {t('by-community', { communityName: displayCommunityData.name })}
                </span>
              </div>
            )}
            <div className="flex flex-row gap-150 min-w-0">
              <TaskBadges
                disabled={isDisabled}
                size="xs"
                badges={quest.tasks.map(({ type }) => type)}
                limit={2}
              />
              <VoteBadge quest={quest} />
              <Recurrence {...quest} />
            </div>
          </div>
        </Root>

        <RewardStack quest={quest} />

        <div className="gradient-border w-full h-full absolute inset-0 after:transition-colors" />
        <div className="absolute top-[calc(100%-16px)] left-[50%] origin-top translate-x-[-50%]">
          <QuestStatus {...quest} />
        </div>
      </div>
    </QuestUnlockInfo>
  );
};

QuestCard.Skeleton = QuestCardSkeleton;
